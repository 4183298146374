<template>
  <div class="table layout-vertical" @click.stop="handleClickStop" @dblclick.stop="handleClickStop">
    <div class="table-row" v-for="(item, index) in items" :key="index">
      <div class="table-column padding padding-large font-size-medium layout-vertical layout-center layout-middle">
        <div>{{ index + 1 }}</div>
      </div>
      <div class="table-column">
        <input-presentation
            ref="input"
            class="input-item"
            :placeholder="isEdit ? '请输入内容' : ''"
            :value="item"
            :data-index="index"
            @blur="handleBlur(index)"
            @focus="handleFocus(index)"
            @input="handleInput($event, index)"
            @keyenter="handleKeyEnter($event, index)"
            @keybackspace="handleKeyBackspace(index)"
        ></input-presentation>
      </div>
    </div>
  </div>
</template>

<script>
import InputPresentation from "@/components/pretansation/basic/InputPresentation";
import GuidUtils from "@/assets/javascript/guid-utils";

export default {
  name: "PresentationTable",
  components: {InputPresentation},
  props: {
    presentation: Object,
  },
  data() {
    return {
      GuidUtils,

      isEdit: true,

      items: [],

      focusList: [],
    }
  },
  watch: {
    presentation: {
      handler: function (presentation) {
        this.items = presentation.items;
        if (this.items.length === 0) {
          this.items.push(null);
        }

      },
      immediate: true,
    }
  },
  methods: {
    requireFocus: function () {
      this.getInputViewByIndex(0)?.focus();
    },
    handleClickStop: function () {

    },
    handleInput: function (e, index) {
      this.items.splice(index, 1, e);
    },
    handleFocus: function (index) {
      this.doRefreshFocusState(index, true);
    },
    handleBlur: function (index) {
      this.doRefreshFocusState(index, false);
    },
    handleKeyEnter: function (e, index) {
      let insertBefore = e.insertBefore && this.items[index];
      let insertIndex = insertBefore ? index : index + 1;
      this.items.splice(insertIndex, 0, null);
      setTimeout(() => {
        this.getInputViewByIndex(insertIndex).focus();
      }, 200);
    },
    handleKeyBackspace: function (index) {
      if (!this.items[index]) {
        this.items.splice(index, 1);
      }

      if(this.items.length === 0) {
        this.$emit('keybackspace', {removed: true});
        return;
      }

      index = index - 1;
      if (index < 0) index = 0;
      if (index >= this.items.length) {
        this.items.push(null);
      }

      this.$nextTick(() => {
        this.getInputViewByIndex(index).focus();
      });
    },
    doRefreshFocusState(index, focus) {
      let focusList = this.focusList;
      if (focusList.length != this.items.length) {
        focusList = new Array(this.items.length);
        for (let n = 0; n < focusList.length; n++) {
          focusList[n] = n < this.focusList.length ? this.focusList[n] : false;
        }
      }
      focusList[index] = focus;
      let isFocused = false;
      for (let focus of focusList) {
        if (focus) isFocused = true;
      }
      if (isFocused) {
        this.$emit('focus');
      } else {
        this.$emit('blur');
      }
    },
    getInputViewByIndex(index) {
      for (let input of this.$refs.input) {
        if (input.$el.dataset.index == index) return input;
      }
      return null;
    }
  }
}
</script>

<style scoped>

.table {
  margin: 16px;
}

.table-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.table-column {
  margin: 2px;
  background-color: #f1f1f1;
  padding: 8px;
  border-radius: 8px
}

.table-column:first-child {
  width: 38px;
  flex-shrink: 0;
}

.table-column:last-child {
  flex: 1 1;
}


.input-item {
  font-size: 16px;
  line-height: 32px;
}

</style>