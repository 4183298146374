<template>
  <div class="scheme padding-left-large margin-left layout-vertical padding-right-large">
    <operation-bar class="layout-inflexible">
      <operation title="返回" icon="fas fa-arrow-left" @click="handleBackClick"></operation>
      <!--<operation title="保存" icon="fas fa-save" divider @click="handleSaveClick"></operation>-->
    </operation-bar>
    <div class="layout-flexible" style="overflow: hidden">
      <ys-infinite-scroll-view style="height: 100%;">
        <div class="margin">
          <div class="table">
            <div class="table-row">
              <div class="table-column">
                基础属性
              </div>
              <div class="table-column">
                <div>
                  <span class="label"><i class="mark-required"></i> 名称：</span>
                  <ys-input
                      class="input-property"
                      placeholder="请输入干预方案名称"
                      hollow
                      v-model="title"
                      :maxlength="SchemeUtils.TITLE_MAX_LENGTH"
                      @input="handleTitleInput"
                  ></ys-input>
                </div>
                <div class="margin-top">
                  <span class="label">摘要：</span>
                  <ys-input
                      class="input-property"
                      placeholder="请输入干预方案摘要信息"
                      hollow
                      v-model="message"
                      :maxlength="SchemeUtils.MESSAGE_MAX_LENGTH"
                      @input="handleMessageInput"
                  ></ys-input>
                </div>
                <div class="margin-top-large">
                  <span class="label"><i class="mark-required"></i> 缩略图：</span>
                </div>
                <ys-image-picker
                    only-pick
                    :auto-upload="false"
                    class="image-thumbnail margin-left-large margin-top multiline-value"
                    :image.sync="image"
                    @update:image="handleImageUpdate"
                ></ys-image-picker>
                <div class="margin-top-large">
                  <span class="label"><i class="mark-required"></i> 干预天数：</span>
                  <ys-number
                      placeholder="请输入干预方案执行天数"
                      hollow
                      v-model="predictedDays"
                      :min="ExactNumber.of(1)"
                      :max="ExactNumber.of(3650)"
                      @input="handlePredictedDaysInput"
                  ></ys-number>
                </div>
                <div class="margin-top-large">
                  <span class="label"><i class="mark-required"></i> 营养师：</span>
                  <div class="dietitian-card multiline-value layout-horizontal layout-middle margin-top"
                       v-if="dietitian"
                       @click="handleAddDietitianClick">
                    <user-avatar :user="dietitian"></user-avatar>
                    <div class="font-size-medium margin-left">{{ dietitian.name }}</div>
                    <ys-button type="secondary" round class="btn-delete margin-left"
                               @click.stop="handleDeleteDietitianClick"><i
                        class="far fa-trash-alt font-color-danger"></i></ys-button>
                  </div>
                  <ys-button v-else type="secondary" @click="handleAddDietitianClick">
                    + 选择营养师
                  </ys-button>
                </div>
                <div class="margin-top-large label">关联问卷：</div>
                <ys-movable
                    class="margin-top multiline-value"
                    @move="handleQuestionnaireMove">
                  <ys-movable-item
                      v-for="(questionnaire, index) in questionnaireList"
                      :key="`${questionnaire.id}_${index}`"
                      class="margin-top"
                      :tag="index"
                  >
                    <div class="reference-item layout-horizontal layout-middle">
                      <div class="layout-flexible">{{ questionnaire.title }}</div>
                      <ys-button
                          type="secondary"
                          round
                          class="btn-delete layout-inflexible"
                          @click="handleDeleteQuestionnaireClick(index)"
                      >
                        <i class="far fa-trash-alt font-color-danger"></i>
                      </ys-button>
                    </div>
                  </ys-movable-item>
                  <ys-button
                      v-if="questionnaireList.length < MAX_REFERENCE_COUNT"
                      type="secondary"
                      class="margin-top"
                      @click="handleAddQuestionnaireClick"
                  >+ 添加关联问卷
                  </ys-button>
                </ys-movable>
                <div class="margin-top-large label">关联打卡：</div>
                <ys-movable
                    class="margin-top multiline-value"
                    @move="handlePunchMove"
                >
                  <ys-movable-item
                      v-for="(punch, index) in punchList"
                      :key="`${punch.id}_${index}`"
                      class="margin-top"
                      :tag="index"
                  >
                    <div class="reference-item layout-horizontal layout-middle">
                      <div class="layout-flexible">{{ punch.title }}</div>
                      <ys-button
                          type="secondary"
                          round
                          class="btn-delete layout-inflexible"
                          @click="handleDeletePunchClick(index)"
                      >
                        <i class="far fa-trash-alt font-color-danger"></i>
                      </ys-button>
                    </div>
                  </ys-movable-item>
                  <ys-button
                      v-if="punchList.length < MAX_REFERENCE_COUNT"
                      type="secondary"
                      class="margin-top"
                      @click="handleAddPunchClick"
                  >+ 添加关联打卡
                  </ys-button>
                </ys-movable>
              </div>
            </div>
            <div class="table-row">
              <div class="table-column">
                商品属性
              </div>
              <div class="table-column">
                <div class="margin-top-large">
                  <span class="label"><i class="mark-required"></i> 封面图：</span>
                </div>
                <ys-image-picker
                    only-pick
                    :auto-upload="false"
                    class="image-cover multiline-value margin-left-large margin-top"
                    :image.sync="cover"
                    @update:image="handleCoverUpdate"
                ></ys-image-picker>
                <div class="margin-top-large">
                  <span class="label">商品售价：</span>
                  <ys-number
                      placeholder="请输入商品价格"
                      prefix-icon="icon-rmb"
                      v-model="cost"
                      :min="ExactNumber.of(0)"
                      :max="ExactNumber.of(999999)"
                      :digits="2"
                      @input="handleCostInput"
                  ></ys-number>
                </div>
                <div class="margin-top-large">
                  <span class="label">商品原价：</span>
                  <ys-number
                      placeholder="不填则不显示"
                      prefix-icon="icon-rmb"
                      v-model="originalCost"
                      :min="ExactNumber.of(0)"
                      :max="ExactNumber.of(999999)"
                      :digits="2"
                      @input="handleOriginalCostInput"
                  ></ys-number>
                </div>
                <div class="margin-top-large">
                  <span class="label">商品详情：</span>
                </div>
                <presentation-edit
                    class="multiline-value"
                    v-model="presentationItems"
                    @input="handlePresentationInput"
                ></presentation-edit>
                <div class="margin-top-large">
                  <span class="label">上架/下架：</span>
                </div>
                <div class="multiline-value margin-left-large margin-top">
                  <ys-checker
                      title="立即上架"
                      :value="commodityState === CommodityState.ON_SHELVES"
                      @input="handleCommodityStateInput($event ? CommodityState.ON_SHELVES : CommodityState.OFF_SHELVES)"
                  ></ys-checker>
                  <ys-checker
                      title="下架"
                      class="margin-left-large"
                      :value="commodityState === CommodityState.OFF_SHELVES"
                      @input="handleCommodityStateInput($event ? CommodityState.OFF_SHELVES : CommodityState.ON_SHELVES)"
                  ></ys-checker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <questionnaire-picker-dialog
        :visible.sync="questionnairePickerDialogVisible"
        :organization-id="organizationId"
        :default-selected-questionnaire-ids="questionnaireList.map(x=>x.id)"
        @pick="handleQuestionnairePicked"
        :max-selected-count="MAX_REFERENCE_COUNT - questionnaireList.length"
    ></questionnaire-picker-dialog>
    <punch-picker-dialog
        :visible.sync="punchPickerDialogVisible"
        :organization-id="organizationId"
        :default-selected-ids="punchList.map(x=>x.id)"
        @pick="handlePunchPicked"
        :max-selected-count="MAX_REFERENCE_COUNT - punchList.length"
    ></punch-picker-dialog>
    <dietitian-picker-dialog
        :visible.sync="dietitianPickerDialogVisible"
        :organization-id="organizationId"
        :max-selected-count="1"
        @pick="handleDietitianPicked"
    ></dietitian-picker-dialog>
  </div>
</template>

<script>
import httpapi, {RequestType} from "@/assets/javascript/httpapi";
import OperationBar from "@/components/operationbar/OperationBar";
import Operation from "@/components/operationbar/Operation";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsButton from "@/components/wedigets/YsButton";
import YsImagePicker from "@/components/wedigets/YsImagePicker";
import YsInput from "@/components/wedigets/YsInput";
import YsNumber from "@/components/wedigets/YsNumber";
import YsChecker from "@/components/wedigets/YsChecker";
import YsMovable from "@/components/wedigets/YsMovable";
import YsMovableItem from "@/components/wedigets/YsMovableItem";
import PresentationEdit from "@/components/pretansation/PresentationEdit";
import QuestionnairePickerDialog from "@/components/dialog/QuestionnairePickerDialog";
import SchemeUtils from "@/assets/javascript/scheme-utils";
import PunchPickerDialog from "@/components/dialog/PunchPickerDialog";
import CommodityUtils, {CommodityState} from "@/assets/javascript/commodity-utils";
import UserAvatar from "@/components/user/UserAvatar";
import DietitianPickerDialog from "@/components/dialog/DietitianPickerDialog";
import ExactNumber from "@/assets/javascript/exact-number";
import ImageUploadHelper from "@/assets/javascript/image-upload-helper";
import TextUtils from "@/assets/javascript/text-utils";
import AutosubmitHelper from "@/assets/javascript/autosubmit-helper";


function doLoadScheme(id) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/scheme/get/with_commodity',
    data: {
      id,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.scheme = res.data;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
    this.$router.back();
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })

}

function doAddScheme() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/scheme/add',
    data: {
      organizationId: this.organizationId,
      title: this.scheme.title,
      message: this.scheme.message,
      image: this.scheme.image,
      predictedDays: ExactNumber.toFloat(this.scheme.predictedDays).toFixed(0),
      punchIds: this.scheme.punchList.map(x => x.id).join(','),
      questionnaireIds: this.scheme.questionnaireList.map(x => x.id).join(','),
      dietitianId: this.scheme.dietitian?.id,
      commodity: this.scheme.commodity,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.scheme.id = res.data.id;
    this.$message.success('保存成功');
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('保存失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doEditScheme() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/scheme/edit',
    data: {
      id: this.scheme.id,
      title: this.scheme.title,
      message: this.scheme.message,
      image: this.scheme.image,
      predictedDays: ExactNumber.toFloat(this.predictedDays).toFixed(0),
      punchIds: this.scheme.punchList.map(x => x.id).join(','),
      questionnaireIds: this.scheme.questionnaireList.map(x => x.id).join(','),
      dietitianId: this.scheme.dietitian?.id,
      commodity: this.scheme.commodity
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.success('保存成功');
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('保存失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

const MAX_REFERENCE_COUNT = 10;

export default {
  name: "Scheme",
  components: {
    DietitianPickerDialog,
    UserAvatar,
    PunchPickerDialog,
    QuestionnairePickerDialog,
    PresentationEdit,
    YsMovableItem,
    YsMovable,
    YsChecker, YsNumber, YsInput, YsImagePicker, YsButton, YsInfiniteScrollView, Operation, OperationBar
  },
  mixins: [httpapi, ImageUploadHelper, AutosubmitHelper],
  props: {
    organizationId: Number,
  },
  data() {
    return {

      /**
       * 常量
       */
      SchemeUtils,
      MAX_REFERENCE_COUNT,
      CommodityState,
      ExactNumber,

      /**
       * 加载代码
       */
      loadingCode: 1,
      busyLoadingCode: 0,

      /**
       * 显示选择问卷弹窗
       */
      questionnairePickerDialogVisible: false,

      /**
       * 显示选择打卡弹窗
       */
      punchPickerDialogVisible: false,

      /**
       * 营养师选择弹窗
       */
      dietitianPickerDialogVisible: false,

      /**
       * 干预方案
       */
      scheme: null,

      /**
       * 干预方案标题
       */
      title: null,

      /**
       * 干预方案说明
       */
      message: null,

      /**
       * 干预方案缩略图
       */
      image: null,

      /**
       * 干预天数
       */
      predictedDays: null,

      /**
       * 营养师
       */
      dietitian: null,

      /**
       * 关联的问卷列表
       */
      questionnaireList: [],

      /**
       * 关联的打卡
       */
      punchList: [],

      /**
       * 商品信息
       */
      commodity: null,

      /**
       * 商品封面图
       */
      cover: null,

      /**
       * 售价
       */
      cost: null,

      /**
       * 原价
       */
      originalCost: null,

      /**
       * 描述信息
       */
      presentationItems: [],

      /**
       * 商品状态
       */
      commodityState: CommodityState.OFF_SHELVES,

      imagesHolder: {
        cover: null,
        image: null,
      }
    }
  },
  watch: {
    scheme: function (scheme) {
      this.title = scheme.title;
      this.message = scheme.message;
      this.image = scheme.image;
      this.predictedDays = scheme.predictedDays ? ExactNumber.of(scheme.predictedDays) : null;
      this.dietitian = scheme.dietitian;
      this.questionnaireList = scheme.questionnaireList;
      this.punchList = scheme.punchList;

      let commodity = scheme.commodity ?? CommodityUtils.defaultCommodity();
      this.commodity = commodity;
      this.cover = commodity.cover;
      this.cost = commodity.cost;
      this.originalCost = commodity.originalCost;
      this.presentationItems = commodity.presentationItems;
      this.commodityState = commodity.state;
    }
  },
  activated() {
    this.loadingCode++;
    if (this.$route.query.id) {
      this.loadScheme();
    } else {
      this.scheme = SchemeUtils.defaultScheme();
    }
  },
  methods: {
    loadScheme: function () {
      doLoadScheme.bind(this)(this.$route.query.id);
    },
    handleBackClick: function () {
      this.$router.back();
    },
    handleSaveClick: function () {
      if (!this.doCheckScheme()) {
        return;
      }
      this.startUpload(this.imagesHolder).then(res => {
        if (res.success) {
          if (this.scheme.id) {
            doEditScheme.bind(this)();
          } else {
            doAddScheme.bind(this)();
          }
        }
      });
    },
    handleTitleInput: function () {
      this.scheme.title = this.title;
      this.createSubmitSchemeTask();
    },
    handleMessageInput: function () {
      this.scheme.message = this.message;
      this.createSubmitSchemeTask();
    },
    handleImageUpdate: function () {
      this.scheme.image = this.image;
      this.imagesHolder.image = this.image;
      this.startUpload(this.imagesHolder)
          .then(() => {
            this.createSubmitSchemeTask();
          })
    },
    handlePredictedDaysInput: function () {
      this.scheme.predictedDays = ExactNumber.toFloat(this.predictedDays);
      this.createSubmitSchemeTask();
    },
    handleAddDietitianClick: function () {
      this.dietitianPickerDialogVisible = true;
    },
    handleDietitianPicked: function (dietitianList) {
      this.dietitian = dietitianList[0];
      this.scheme.dietitian = this.dietitian;
      this.createSubmitSchemeTask();
    },
    handleDeleteDietitianClick: function () {
      this.dietitian = null;
      this.scheme.dietitian = null;
      this.createSubmitSchemeTask();
    },
    handleAddQuestionnaireClick: function () {
      this.questionnairePickerDialogVisible = true;
    },
    handleQuestionnairePicked: function (questionnaireList) {
      for (let questionnaire of questionnaireList) {
        let duplicated = false;
        for (let n = 0; n < this.questionnaireList.length; n++) {
          if (this.questionnaireList[n].id === questionnaire.id) {
            duplicated = true;
            break;
          }
        }
        if (!duplicated) {
          this.questionnaireList.push(questionnaire);
        }
      }
      this.scheme.questionnaireList = this.questionnaireList;
      this.createSubmitSchemeTask();
    },
    handleDeleteQuestionnaireClick: function (index) {
      this.questionnaireList.splice(index, 1);
      this.scheme.questionnaireList = this.questionnaireList;
      this.createSubmitSchemeTask();
    },
    handleQuestionnaireMove: function (e) {
      let from = e.from;
      let to = e.to;
      if (e.direct === 'bottom') {
        to = e.to + 1;
      }
      if (from < to) to--;
      let questionnaire = this.questionnaireList[from];
      this.questionnaireList.splice(from, 1);
      this.questionnaireList.splice(to, 0, questionnaire);
      this.scheme.questionnaireList = this.questionnaireList;
      this.createSubmitSchemeTask();
    },
    handleAddPunchClick: function () {
      this.punchPickerDialogVisible = true;
    },
    handlePunchPicked: function (punchList) {
      for (let punch of punchList) {
        let duplicated = false;
        for (let n = 0; n < this.punchList.length; n++) {
          if (this.punchList[n].id === punch.id) {
            duplicated = true;
            break;
          }
        }
        if (!duplicated) {
          this.punchList.push(punch);
        }
      }
      this.scheme.punchList = this.punchList;
      this.createSubmitSchemeTask();
    },
    handleDeletePunchClick: function (index) {
      this.punchList.splice(index, 1);
      this.scheme.punchList = this.punchList;
      this.createSubmitSchemeTask();
    },
    handlePunchMove: function (e) {
      let from = e.from;
      let to = e.to;
      if (e.direct === 'bottom') {
        to = e.to + 1;
      }
      if (from < to) to--;
      let punch = this.punchList[from];
      this.punchList.splice(from, 1);
      this.punchList.splice(to, 0, punch);
      this.scheme.punchList = this.punchList;
      this.createSubmitSchemeTask();
    },
    handleCoverUpdate: function () {
      this.commodity.cover = this.cover;
      this.scheme.commodity = this.commodity;
      this.imagesHolder.cover = this.cover;
      this.startUpload(this.imagesHolder)
          .then(() => this.createSubmitSchemeTask());
    },
    handleCostInput: function () {
      this.commodity.cost = this.cost;
      this.scheme.commodity = this.commodity;
      this.createSubmitCommodityTask();
    },
    handleOriginalCostInput: function () {
      this.commodity.originalCost = this.originalCost;
      this.scheme.commodity = this.commodity;
      this.createSubmitCommodityTask();
    },
    handlePresentationInput: function (e) {
      this.commodity.presentationItems = e;
      this.scheme.commodity = this.commodity;
      this.createSubmitCommodityTask();
    },
    handleCommodityStateInput: function (state) {
      this.commodityState = state;
      this.commodity.state = state;
      this.scheme.commodity = this.commodity;
      this.createSubmitCommodityTask();
    },
    doCheckScheme: function () {
      if (TextUtils.isBlank(this.scheme.title)) {
        return false;
      }
      if (TextUtils.isBlank(this.scheme.message)) {
        return false;
      }
      if (!this.scheme.image) {
        return false;
      }
      if (!this.predictedDays) {
        return false;
      }

      return true;
    },
    doCheckCommodity: function () {
      if (!this.commodity.cover) {
        return false;
      }
      return true;
    },
    createSubmitSchemeTask() {
      this.createTask({
        tag: 'scheme_' + this.scheme.id,
        runFunc: this.submitScheme,
        params: this.scheme
      });
    },
    createSubmitCommodityTask() {
      this.createTask({
        tag: 'scheme_commodity_' + this.scheme.id,
        runFunc: this.submitCommodity,
        params: {
          scheme: this.scheme,
          commodity: this.commodity,
        }
      })
    },
    async submitScheme(scheme) {
      if (!this.doCheckScheme()) return;
      if (scheme.id !== 0) {
        await this.setScheme(scheme);
      } else {
        await this.addScheme(scheme);
      }
    },
    async submitCommodity({scheme, commodity}) {
      if (!this.doCheckCommodity()) return;
      if (scheme.id !== 0) {
        await this.setCommodityToScheme({scheme, commodity});
      } else {
        await this.addScheme(scheme);
        if (scheme.id !== 0) await this.setCommodityToScheme({scheme, commodity});
      }
    },
    addScheme(scheme) {
      return this.$reqPost({
        path: `/scheme/${this.organizationId}/${encodeURIComponent(scheme.title)}/${scheme.image.id}`,
        type: RequestType.X_WWW_FORM_URLENCODED,
        data: {
          message: scheme.message,
          punchIds: scheme.punchList.map(x => x.id).join(','),
          questionnaireIds: scheme.questionnaireList.map(x => x.id).join(','),
          predictedDays: scheme.predictedDays,
          dietitianId: scheme.dietitian?.id
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            scheme.id = res.data.id;
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    setScheme(scheme) {
      return this.$reqPut({
        path: `/scheme/${scheme.id}/${encodeURIComponent(scheme.title)}/${scheme.image.id}`,
        type: RequestType.X_WWW_FORM_URLENCODED,
        data: {
          message: scheme.message,
          punchIds: scheme.punchList.map(x => x.id).join(','),
          questionnaireIds: scheme.questionnaireList.map(x => x.id).join(','),
          predictedDays: scheme.predictedDays,
          dietitianId: scheme.dietitian?.id
        }
      }).returnWhenCodeMatches()
          .catch(() => {
            this.$message.error('保存失败');
          })
          .promise();
    },
    setCommodityToScheme({scheme, commodity}) {
      return this.$reqPut({
        path: `/scheme/commodity/${scheme.id}`,
        type: RequestType.JSON,
        data: {
          cover: commodity.cover,
          cost: commodity.cost,
          originalCost: commodity.originalCost,
          presentationItems: commodity.presentationItems,
          state: commodity.state
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            if (!scheme.commodity) {
              scheme.commodity = res.data;
            }
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    }
  }
}
</script>

<style scoped>

.scheme {
  height: 100vh;
}

.table {
  width: 60vw;
}

.table-row {
  display: flex;
  flex-direction: row;
}

.table-row .table-column {
  background-color: white;
  box-shadow: 0 0 16px #dadada;
  margin: 4px;
  padding: 16px;
}

.table-row .table-column {
  flex-grow: 1;
}

.table-row .table-column:first-child {
  flex-shrink: 0;
  flex-grow: 0;
  width: 64px;
}


.table-row:first-child .table-column:first-child {
  border-top-left-radius: 16px;
}

.table-row:first-child .table-column:last-child {
  border-top-right-radius: 16px;
}

.table-row:last-child .table-column:first-child {
  border-bottom-left-radius: 16px;
}

.table-row:last-child .table-column:last-child {
  border-bottom-right-radius: 16px;
}

.label {
  width: 80px;
  text-align: right;
  display: inline-block;
}

.multiline-value {
  margin-left: 80px;
}

.mark-required:before {
  content: "*";
  color: #ff5500;
}

.input-property {
  width: 500px;
}

.reference-item {
  width: 30vw;
  padding: 8px 16px;
  background-color: #f1f1f1;
  border-radius: 16px;
}

.reference-item .btn-delete {
  visibility: hidden;
}

.reference-item:hover .btn-delete {
  visibility: visible;
}

.dietitian-card {
  cursor: pointer;
  user-select: none;
}

.dietitian-card .btn-delete {
  visibility: hidden;
}

.dietitian-card:hover .btn-delete {
  visibility: visible;
}

.image-thumbnail {
  width: 128px;
  height: 88px;
}

.image-cover {
  width: 128px;
  height: 88px;
}

</style>