<template>
  <div :class="[
      'appendix',
      {'is-edit': isEdit}
  ]" @click.stop="handleClickStop" @dblclick.stop="handleClickStop">
    <span class="fas fa-quote-right margin-left font-size-small font-color-light-placeholder"></span>
    <input-presentation
        ref="input"
        class="input-appendix font-color-secondary"
        v-model="appendixInputted"
        :placeholder="isEdit ? '说明' : ''"
        @blur="handleBlur"
        @focus="handleFocus"
        @input="handleInput"
        @keyenter="handleKeyEnter"
        @keybackspace="handleKeyBackspace">
    </input-presentation>
  </div>
</template>

<script>
import InputPresentation from "@/components/pretansation/basic/InputPresentation";

export default {
  name: "PresentationAppendix",
  components: {InputPresentation},
  props: {
    presentation: Object,
  },
  data() {
    return {
      isEdit: false,

      appendixInputted: null,
    }
  },
  watch: {
    presentation: {
      handler: function (presentation) {
        this.appendixInputted = presentation.content;
      },
      immediate: true,
    },
  },
  methods: {
    requireFocus: function () {
      this.$refs.input.focus();
    },
    handleClickStop: function () {

    },
    handleBlur: function () {
      this.isEdit = false;
      this.$emit('blur');
    },
    handleFocus: function () {
      this.isEdit = true;
      this.$emit('focus');
    },
    handleInput: function () {
      this.presentation.content = this.appendixInputted;
      this.$emit('input', this.presentation);
    },
    handleKeyEnter: function (e) {
      this.$emit('keyenter', {insertBefore: e.insertBefore && this.appendixInputted});
    },
    handleKeyBackspace: function () {
      this.$emit('keybackspace', {removed: !this.appendixInputted});
    }
  }
}
</script>

<style scoped>

.appendix {
  background-color: #f1f1f1;
  padding: 16px;
  margin: 10px;
  border-radius: 4px;
}

.input-appendix {
  font-size: 14px;
  line-height: 24px;
  padding-left: 16px;
  padding-right: 16px;
}


</style>