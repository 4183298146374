<template>
  <div
      :class="[
          'title',
          {'is-edit': isEdit}
      ]"
      @click.stop="handleClickStop"
      @dblclick.stop="handleClickStop">
    <div class="input-paragraph-content__wrapper">
      <input-presentation
          ref="input"
          class="input-paragraph-content"
          v-model="titleInputted"
          :placeholder="isEdit ? '标题' : '>标题'"
          @keyenter="handleKeyEnter"
          @keybackspace="handleKeyBackspace"
          @focus="handleFocus"
          @blur="handleBlur"
          @input="handleInput"
      ></input-presentation>
    </div>
    <!--<ys-textarea
        ref="input"
        class="input-paragraph-content"
        :show-background="false"
        :placeholder="isEdit ? '请输入标题' : '﹤标题'"
        :rows="1"
        :autosize="{minRows: 1, maxRows: 65530}"
        :key-enter="handleKeyEnter"
        @keyup.backspace.native="handleBackSpace"
        v-model="titleInputted"
        @blur="handleBlur"
        @focus="handleFocus"
        @input="handleInput"
    ></ys-textarea>-->
  </div>
</template>

<script>
import YsTextarea from "@/components/wedigets/YsTextarea";
import ContentEditableHelper from "@/components/pretansation/basic/content-editable-helper";
import InputPresentation from "@/components/pretansation/basic/InputPresentation";

export default {
  name: "PresentationTitle",
  components: {InputPresentation, YsTextarea},
  props: {
    presentation: Object,
  },
  data() {
    return {
      isEdit: true,
      titleInputted: null,
    }
  },
  watch: {
    presentation: {
      handler: function (presentation) {
        this.titleInputted = presentation.title;
      },
      immediate: true,
    }
  },
  methods: {
    requireFocus: function () {
      this.$refs.input.focus();
    },
    handleClickStop: function () {

    },
    handleKeyEnter: function (e) {
      this.$emit('keyenter', { insertBefore: e.insertBefore && this.titleInputted})
      return false;
    },
    handleFocus: function () {
      this.isEdit = true;
      this.$emit('focus');
    },
    handleBlur: function () {
      this.isEdit = false;
      this.$emit('blur');
    },
    handleInput: function (e) {
      this.titleInputted = e
      this.presentation.title = this.titleInputted;
      this.$emit('input', {value: this.presentation});
    },
    handleKeyBackspace: function () {
      this.$emit('keybackspace', {removed: !this.titleInputted});
    }
  }
}
</script>

<style scoped>

.paragraph-content {
  font-size: 18px;
  line-height: 32px;
  padding: 0px;
  padding-left: 16px;
  padding-right: 16px;
}

.input-paragraph-content {
  padding: 0px;
  margin: 0px;
  font-size: 18px;
  line-height: 32px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  outline: none;
  user-select: auto;
  font-weight: 500;
}

.input-paragraph-content:after {
  content: "↵";
  color: #dadada
}

</style>