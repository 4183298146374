const ContentEditableHelper = {
    init: function (node) {
        return {
            requireFocus: function (start = null) {
                node.focus();
                let selection = document.getSelection();
                let range = document.createRange();
                if(start === null) {
                    start = node.textContent?.length;
                }
                if(node.firstChild) {
                    range.setStart(node.firstChild, start);
                    range.setEnd(node.firstChild, start);
                } else {
                    range.setStart(node, start);
                    range.setEnd(node, start);
                }
                selection.removeAllRanges();
                selection.addRange(range);

            },
            getSelectionStart: function () {
                let selection = document.getSelection();
                if (selection.rangeCount > 0) {
                    return selection.getRangeAt(0).startOffset;
                } else {
                    return 0;
                }
            },
            getSelection: function () {
                let selection = document.getSelection();
                if (selection.rangeCount > 0) {
                    return {start: selection.getRangeAt(0).startOffset, end: selection.getRangeAt(0).endOffset};
                } else {
                    return {start: 0, end: 0};
                }
            }
        }

    }
}

export default ContentEditableHelper;