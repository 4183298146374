<template>
  <div class="operation-bar-wrapper">
    <div class="layout-horizontal operation-bar">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "OperationBar"
}
</script>

<style scoped>

.operation-bar-wrapper {
  width: inherit;
  overflow: hidden;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
}

.operation-bar {
  background-color: white;
  box-shadow: 0 0 4px #bec0c0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

</style>