import GuidUtils from "@/assets/javascript/guid-utils";

export const PresentationItemType = {

    /**
     * 标题
     */
    TITLE: 0,

    /**
     * 段落
     */
    PARAGRAPH: 1,

    /**
     * 图片
     */
    IMAGE: 2,

    /**
     * 服务范围
     */
    SERVICE_AREA: 3,

    /**
     * 附录
     */
    APPENDIX: 4,

}

function defaultTitle() {
    return {
        type: PresentationItemType.TITLE,
        guid: GuidUtils.guid(),
        title: null,
    }
}

function defaultParagraph() {
    return {
        type: PresentationItemType.PARAGRAPH,
        guid: GuidUtils.guid(),
        content: null,
    }
}

function defaultImage() {
    return {
        type: PresentationItemType.IMAGE,
        guid: GuidUtils.guid(),
        image: null,
        title: null,
    }
}

function defaultServiceArea() {
    return {
        type: PresentationItemType.SERVICE_AREA,
        guid: GuidUtils.guid(),
        items: [],
    }
}

function defaultAppendix() {
    return {
        type: PresentationItemType.APPENDIX,
        guid: GuidUtils.guid(),
        content: null,
    }
}

function convert2Title(item) {
    switch (item.type) {
        case PresentationItemType.TITLE: {
            return item;
        }
        case PresentationItemType.PARAGRAPH: {
            let title = defaultTitle();
            title.title = item.content;
            title.guid = item.guid;
            return title;
        }
        case PresentationItemType.APPENDIX: {
            let title = defaultTitle();
            title.title = item.content;
            title.guid = item.guid;
            return title;
        }
        default: {
            return item;
        }
    }
}

function convert2Paragraph(item) {
    switch (item.type) {
        case PresentationItemType.TITLE: {
            let paragraph = defaultParagraph();
            paragraph.content = item.title;
            paragraph.guid = item.guid;
            return paragraph;
        }
        case PresentationItemType.PARAGRAPH: {
            return item;
        }
        case PresentationItemType.APPENDIX: {
            let paragraph = defaultParagraph();
            paragraph.content = item.content;
            paragraph.guid = item.guid;
            return paragraph;
        }
        default: {
            return item;
        }
    }
}

function isEmptyParagraph(item) {
    return item.type === PresentationItemType.PARAGRAPH && !item.content;
}

function convert2Appendix(item) {
    switch (item.type) {
        case PresentationItemType.TITLE: {
            let appendix = defaultAppendix();
            appendix.content = item.title;
            appendix.guid = item.guid;
            return appendix;
        }
        case PresentationItemType.PARAGRAPH: {
            let appendix = defaultAppendix();
            appendix.content = item.content;
            appendix.guid = item.guid;
            return appendix;
        }
        case PresentationItemType.APPENDIX: {
            return item;
        }
        default: {
            return item;
        }
    }
}

const PresentationUtils = {
    defaultTitle,
    defaultParagraph,
    defaultAppendix,
    defaultImage,
    defaultServiceArea,
    convert2Title,
    convert2Paragraph,
    convert2Appendix,
    isEmptyParagraph,
}

export default PresentationUtils;