<template>
  <ys-dialog
      :visible.sync="isVisible"
      @update:visible="visible=>$emit('update:visible', visible)"
      :title="title"
  >
    <el-collapse-transition>
      <div v-if="selectedDietitianList.length > 0">
        <div class="margin-horizontal margin-top">已选择：</div>
        <div class="layout-horizontal layout-wrap margin-horizontal margin-top-small">
          <div class="dietitian-picked" v-for="dietitian in selectedDietitianList" :key="dietitian.id"
               @click="handleDietitianClick(dietitian)">
            <span>{{ dietitian.name }}</span>
            <span class="btn-delete"> <i class="el-icon-close font-size-small"></i></span>
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <ys-infinite-scroll-view
        @loadmore="loadMore"
        style="height: 50vh;">
      <div class="padding-horizontal">
        <div
            class="list-item layout-horizontal layout-middle"
            v-for="dietitian in dietitianList"
            :key="dietitian.id"
            @click="handleDietitianClick(dietitian)">
          <ys-checker
              class="layout-inflexible"
              :value="dietitian.__isSelected"
              :disable="dietitian.__isDefaultSelected"
          ></ys-checker>
          <div class="layout-flexible layout-horizontal layout-middle margin-left-large">
            <user-avatar :user="dietitian"></user-avatar>
            <div class="margin-left">
              <div class="font-size-medium">{{ dietitian.name }}</div>
              <div class="font-color-placeholder">{{ dietitian.introduction }}</div>
            </div>
          </div>
        </div>
      </div>
    </ys-infinite-scroll-view>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">取消</ys-button>
      <ys-button class="margin-left-large" lighting @click="handleConfirmClick">
        <span>确定</span>
        <span v-if="selectedDietitianList.length > 0">({{ selectedDietitianList.length }}/{{ maxSelectedCount }})</span>
      </ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import YsDialog from "@/components/wedigets/YsDialog";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsChecker from "@/components/wedigets/YsChecker";
import YsButton from "@/components/wedigets/YsButton";
import UserAvatar from "@/components/user/UserAvatar";

function doLoadDietitianList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/dietitian/list/get/by_organization_id',
    data: {
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.dietitianList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
    this.doRefreshSelection();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "DietitianPickerDialog",
  components: {UserAvatar, YsButton, YsChecker, YsInfiniteScrollView, YsDialog},
  mixins: [httpapi],
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "选择营养师"
    },
    organizationId: Number,
    maxSelectedCount: {
      type: Number,
      default: 1,
    },
    defaultSelectedDietitianIds: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      isVisible: false,

      dietitianList: [],
      pageNum: 1,
      totalPages: 0,

      selectedDietitianList: [],
    }
  },

  watch: {
    visible: {
      handler: function () {
        this.isVisible = this.visible;
        if(this.isVisible) {
          this.selectedDietitianList = [];
          this.doRefreshSelection();
        }
      },
      immediate: true,
    },
    organizationId: {
      handler: function () {
        this.loadingCode++;
        this.pageNum = 1;
        this.totalPages = 0;
        this.dietitianList = [];
        this.loadDietitianList();
      },
      immediate: true,
    },

  },

  methods: {
    loadDietitianList: function (pageNum = 1) {
      doLoadDietitianList.bind(this)(pageNum);
    },
    loadMore: function () {
      if (this.pageNum < this.totalPages) {
        this.loadDietitianList(this.pageNum + 1);
      }
    },
    handleDietitianClick: function (dietitian) {
      if (dietitian.__isDefaultSelected) return;
      let selectedIndex = -1;
      for (let n = 0; n < this.selectedDietitianList.length; n++) {
        if (this.selectedDietitianList[n].id === dietitian.id) {
          selectedIndex = n;
          break;
        }
      }
      if (selectedIndex >= 0) {
        this.selectedDietitianList.splice(selectedIndex, 1);
      } else {
        if(this.selectedDietitianList.length >= this.maxSelectedCount) {
          this.$message.error('最多选择' + this.maxSelectedCount + '项');
          return;
        }
        this.selectedDietitianList.push(dietitian);

        if(this.maxSelectedCount === 1) this.handleConfirmClick();
      }
      this.doRefreshSelection();
    },
    handleCancelClick: function () {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleConfirmClick: function () {
      if(this.selectedDietitianList.length === 0) {
        this.$message.warning('至少选择一项');
        return;
      }
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('pick', this.selectedDietitianList);
    },
    doRefreshSelection: function () {
      for (let dietitian of this.dietitianList) {
        let isSelected = false;
        let isDefaultSelected = false;
        for (let defaultSelectedId of this.defaultSelectedDietitianIds) {
          if (defaultSelectedId === dietitian.id) {
            isSelected = true;
            isDefaultSelected = true;
            break;
          }
        }
        if (!isDefaultSelected) {
          for (let selectedDietitian of this.selectedDietitianList) {
            if (selectedDietitian.id === dietitian.id) {
              isSelected = true;
              break;
            }
          }
        }
        dietitian.__isSelected = isSelected;
        dietitian.__isDefaultSelected = isDefaultSelected;
      }
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>

.list-item {
  width: auto;
}

.dietitian-picked {
  background-color: #f091a6;
  color: white;
  padding: 4px 16px;
  border-radius: 100px;
  cursor: pointer;
  margin: 4px;
}

.dietitian-picked .btn-delete {
  opacity: 0;
}

.dietitian-picked:hover .btn-delete {
  opacity: 1;
}
</style>