<template>
  <div class="layout-horizontal">
    <div class="divider layout-self-stretch" v-if="divider"></div>
    <div class="operation layout-vertical layout-center padding padding-horizontal-large"
         :class="{'is-disable': disable}" @click="handleClick">
      <el-badge :hidden="!showDot" is-dot>
        <div><i :class="[icon]"></i></div>
      </el-badge>
      <div class="font-size-extra-small">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Operation",
  props: {
    /**
     * 图标
     */
    icon: {
      type: String,
      default: "far fa-file"
    },
    /**
     * 标题
     */
    title: {
      type: String,
      default: "新建问卷"
    },
    /**
     * 分割线
     */
    divider: {
      type: Boolean,
      default: false,
    },
    /**
     * 禁用
     */
    disable: {
      type: Boolean,
      default: false,
    },
    /**
     * 显示小红点
     */
    showDot: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleClick: function (e) {
      this.$emit('click', e);
    }
  }
}
</script>

<style scoped>

.operation {
  cursor: pointer;
  user-select: none;
}

.operation:hover {
  background-color: #f1f1f1;
}

.divider {
  height: 100%;
  width: 1px;
  background-color: #dadada;
}

.is-disable {
  color: #bec0c0;
}
</style>