import httpapi from "@/assets/javascript/httpapi";

function doExtractImagesFromObject(obj) {
    if (!obj) return [];
    let images = [];
    let candicates = [obj];
    for (let n = 0; n < candicates.length; n++) {
        let candicate = candicates[n];
        if (!candicate) continue;
        if (typeof candicate !== 'object') continue;
        if (candicate.constructor.name === 'Array') {
            for (let tmp of candicate) {
                candicates.push(tmp);
            }
        } else if (!candicate.__localImageFile) {
            for (let key in candicate) {
                candicates.push(candicate[key]);
            }
        } else {
            images.push(candicate);
        }
    }
    return images;
}

const ImageUploadHelper = {
    mixins: [httpapi],
    data() {
        return {
            /**
             * 待上传的图片列表
             */
            uploadImages: [],

            /**
             * 正在上传的图片
             */
            uploadingImage: null,

            /**
             * 图片上传异步处理
             */
            uploadPromise: null,
        }
    },
    methods: {
        startUpload: function (obj) {
            let images = doExtractImagesFromObject(obj);
            for(let image of images) {
                let isDuplicated = false;
                for(let n = 0; n < this.uploadImages.length; n++) {
                    if(this.uploadImages[n] === image) {
                        isDuplicated = true;
                        break;
                    }
                }
                if(!isDuplicated) {
                    this.uploadImages.push(image);
                }
            }

            if(this.uploadPromise) return this.uploadPromise;

            let promise = new Promise(resolve=>{
                if(this.uploadImages.length > 0) {
                    this.doUploadImage(resolve);
                } else {
                    resolve({
                        success: true,
                    })
                }
            });

            if(this.uploadImages.length > 0) this.uploadPromise = promise;
            return promise;
        },
        doUploadImage(resolve) {
            let image = this.uploadImages[0];
            this.$uploadImage({
                imageFile: image.__localImageFile,
            }).then(res=>{
                image.id = res.data.id;
                image.url = res.data.url;
                delete image.__localImageFile;
                this.uploadImages.splice(0, 1);
                if(this.uploadImages.length === 0) {
                    this.uploadPromise = null;
                    resolve({
                        success: true,
                    })
                } else {
                    this.doUploadImage(resolve);
                }
            }).catch(()=>{
                this.uploadPromise = null;
                resolve({
                    failed: true,
                })
            })
        }


    }
}

export default ImageUploadHelper;