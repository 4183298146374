function defaultScheme() {
    return {
        id: 0,
        organizationId: 0,
        title: null,
        image: null,
        message: null,
        predictedDays: null,
        punchList: [],
        questionnaireList: [],
        dietitian: null,
    }
}

function defaultSchemeStage() {
    return {
        title: null,
        message: null,
        predictedDays: null,
        payment: null,
        punchIds: [],
        questionnaireIds: [],
    }

}

const SchemeUtils = {
    TITLE_MAX_LENGTH: 80,
    MESSAGE_MAX_LENGTH: 200,
    SCHEME_STAGE_MAX_COUNT: 20,
    defaultScheme: defaultScheme,
    defaultSchemeStage: defaultSchemeStage,
}

export default SchemeUtils;