<template>
  <div class="presentation-edit layout-vertical">
    <div class="operation-bar layout-inflexible layout-horizontal">
      <div class="font-size-small font-color-placeholder layout-self-middle margin-horizontal">
        <div>布</div>
        <div>局</div>
      </div>
      <div class="operation" @click="handleTitleMenuClick">
        <div class="operation__icon"><i class="fas fa-list"></i></div>
        <div class="operation__title">标题</div>
      </div>
      <div class="operation" @click="handleParagraphMenuClick">
        <div class="operation__icon"><i class="fas fa-align-justify"></i></div>
        <div class="operation__title">段落</div>
      </div>
      <div class="operation" @click="handleAppendixMenuClick">
        <div class="operation__icon"><i class="fas fa-paperclip"></i></div>
        <div class="operation__title">说明</div>
      </div>
      <div class="divider divider-vertical"></div>
      <div class="font-size-small font-color-placeholder layout-self-middle margin-horizontal">
        <div>插</div>
        <div>入</div>
      </div>
      <div class="operation" @click="handleImageMenuClick">
        <div class="operation__icon"><i class="fas fa-image"></i></div>
        <div class="operation__title">图片</div>
      </div>
      <div class="operation" @click="handleTableMenuClick">
        <div class="operation__icon"><i class="fas fa-table"></i></div>
        <div class="operation__title">列表</div>
      </div>
    </div>
    <div style="height: 0px; overflow: hidden;">
      <ys-image-picker ref="imagePicker" only-pick @update:image="handleImagePicked"></ys-image-picker>
    </div>
    <div class="layout-flexible" style="overflow: hidden" @click="handleEditPanelClick"
         @dblclick="handleEditPanelDblClick">
      <ys-infinite-scroll-view style="height: 100%">
        <div class="layout-vertical padding-bottom-large">
          <div class="page-header" @dblclick.stop="handlePageHeaderDblClick" @click.stop="handleClickStop">
            <div class="btn-insert-paragraph">--双击此处添加段落--</div>
          </div>
          <div v-for="(presentationItem, index) in presentationItems" :key="presentationItem.guid">
            <presentation-title
                v-if="presentationItem.type === PresentationItemType.TITLE"
                ref="presentation-items"
                :presentation="presentationItem"
                @keyenter="handleKeyEnter($event, index)"
                @keybackspace="handleKeyBackspace($event, index)"
                @focus="handleFocus(index)"
                @input="handleInput($event, index)"
            ></presentation-title>
            <presentation-paragraph
                v-else-if="presentationItem.type === PresentationItemType.PARAGRAPH"
                ref="presentation-items"
                :presentation="presentationItem"
                @keyenter="handleKeyEnter($event, index)"
                @keybackspace="handleKeyBackspace($event, index)"
                @focus="handleFocus(index)"
                @input="handleInput($event, index)"
            ></presentation-paragraph>
            <presentation-appendix
                v-else-if="presentationItem.type === PresentationItemType.APPENDIX"
                ref="presentation-items"
                :presentation="presentationItem"
                @keyenter="handleKeyEnter($event, index)"
                @keybackspace="handleKeyBackspace($event, index)"
                @focus="handleFocus(index)"
                @input="handleInput($event, index)"
            ></presentation-appendix>
            <presentation-image
                v-else-if="presentationItem.type === PresentationItemType.IMAGE"
                ref="presentation-items"
                :presentation="presentationItem"
                @keyenter="handleKeyEnter($event, index)"
                @keybackspace="handleKeyBackspace($event, index)"
                @focus="handleFocus(index)"
                @input="handleInput($event, index)"
            ></presentation-image>
            <presentation-table
                v-else-if="presentationItem.type === PresentationItemType.SERVICE_AREA"
                ref="presentation-items"
                :presentation="presentationItem"
                @keyenter="handleKeyEnter($event, index)"
                @keybackspace="handleKeyBackspace($event, index)"
                @focus="handleFocus(index)"
                @input="handleInput($event, index)"
            ></presentation-table>
          </div>
          <div class="page-header">
            <div class="btn-insert-paragraph">--双击此处添加段落--</div>
          </div>

        </div>
      </ys-infinite-scroll-view>
    </div>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import PresentationParagraph from "@/components/pretansation/basic/PresentationParagraph";
import PresentationTitle from "@/components/pretansation/basic/PresentationTitle";
import PresentationImage from "@/components/pretansation/basic/PresentationImage";
import PresentationUtils, {PresentationItemType} from "@/assets/javascript/presentation-utils";
import PresentationAppendix from "@/components/pretansation/basic/PresentationAppendix";
import PresentationTable from "@/components/pretansation/basic/PresentationTable";
import YsImagePicker from "@/components/wedigets/YsImagePicker";
import ImageUploadHelper from "@/assets/javascript/image-upload-helper";
import GuidUtils from "@/assets/javascript/guid-utils";

export default {
  name: "PresentationEdit",
  mixins: [ImageUploadHelper],
  components: {
    YsImagePicker,
    PresentationTable,
    PresentationAppendix,
    PresentationImage,
    PresentationTitle,
    PresentationParagraph,
    YsInfiniteScrollView
  },
  props: {
    value: Object,
  },
  data() {
    return {
      PresentationItemType,

      presentationItems: [PresentationUtils.defaultParagraph()],

      currentItemIndex: 0,
    }
  },
  watch: {
    value: {
      handler: function(value) {
        for(let presentationItem of value) {
          if(!presentationItem.guid) presentationItem.guid = GuidUtils.guid();
        }
        this.presentationItems = value;
        if (!this.presentationItems || this.presentationItems.length === 0) {
          this.presentationItems = [PresentationUtils.defaultParagraph()];
        }
        if(this.currentItemIndex > this.presentationItems.length - 1) {
          this.currentItemIndex = this.presentationItems.length - 1;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleClickStop: function () {

    },
    handleEditPanelClick: function (e) {
      let lastPresentationItem = this.presentationItems[this.presentationItems.length - 1];
      let presentationItemView = this.getPresentationItemViewById(lastPresentationItem.guid);
      presentationItemView.requireFocus();
      e.returnValue = false;
      return false;
    },
    handleKeyEnter: function (event, index) {
      let paragraph = PresentationUtils.defaultParagraph();
      if (event.insertBefore) {
        this.presentationItems.splice(index, 0, paragraph);
      } else {
        this.presentationItems.splice(index + 1, 0, paragraph);
      }
      this.$nextTick(() => {
        this.getPresentationItemViewById(paragraph.guid).requireFocus();
      })
      this.doTriggerInputEvent();
    },
    handleKeyBackspace: function (e, index) {
      if (e.removed) {
        this.presentationItems.splice(index, 1);
        if (this.presentationItems.length === 0) {
          this.presentationItems.push(PresentationUtils.defaultParagraph());
        }
      }
      this.$nextTick(() => {
        if (index - 1 >= 0) {
          this.getPresentationItemViewById(this.presentationItems[index - 1].guid).requireFocus();
        } else if (index < this.presentationItems.length) {
          this.getPresentationItemViewById(this.presentationItems[index].guid).requireFocus();
        } else {
          this.getPresentationItemViewById(this.presentationItems[0].guid).requireFocus();
        }
      });
      this.doTriggerInputEvent();
    },
    handleFocus: function (index) {
      this.currentItemIndex = index;
    },
    handleTitleMenuClick: function () {
      let title = PresentationUtils.convert2Title(this.presentationItems[this.currentItemIndex]);
      this.presentationItems.splice(this.currentItemIndex, 1, title);
      this.$nextTick(() => {
        this.getPresentationItemViewById(title.guid).requireFocus();
      })
      this.doTriggerInputEvent();
    },
    handleParagraphMenuClick: function () {
      let paragraph = PresentationUtils.convert2Paragraph(this.presentationItems[this.currentItemIndex]);
      this.presentationItems.splice(this.currentItemIndex, 1, paragraph);
      this.$nextTick(() => {
        this.getPresentationItemViewById(paragraph.guid).requireFocus();
      })
      this.doTriggerInputEvent();
    },
    handleAppendixMenuClick: function () {
      let appendix = PresentationUtils.convert2Appendix(this.presentationItems[this.currentItemIndex]);
      this.presentationItems.splice(this.currentItemIndex, 1, appendix);
      this.$nextTick(() => {
        this.getPresentationItemViewById(appendix.guid).requireFocus();
      });
      this.doTriggerInputEvent();
    },
    handleImageMenuClick: function () {
      this.$refs.imagePicker.click();
    },
    handleTableMenuClick: function () {
      let table = PresentationUtils.defaultServiceArea();
      if (PresentationUtils.isEmptyParagraph(this.presentationItems[this.currentItemIndex])) {
        this.presentationItems.splice(this.currentItemIndex, 1, table);
      } else {
        this.presentationItems.splice(this.currentItemIndex + 1, 0, table);
      }
      this.$nextTick(() => {
        this.getPresentationItemViewById(table.guid).requireFocus();
      })
      this.doTriggerInputEvent();
    },
    handleImagePicked: function (image) {
      let presentationImage = PresentationUtils.defaultImage();
      presentationImage.image = image;
      console.log('currentItemIndex: ' + this.currentItemIndex);
      if (PresentationUtils.isEmptyParagraph(this.presentationItems[this.currentItemIndex])) {
        this.presentationItems.splice(this.currentItemIndex, 1, presentationImage);
      } else {
        this.presentationItems.splice(this.currentItemIndex + 1, 0, presentationImage);
      }
      this.$nextTick(() => {
        this.getPresentationItemViewById(presentationImage.guid).requireFocus();
      })
      this.doTriggerInputEvent();
    },
    handlePageHeaderDblClick: function () {
      if (!PresentationUtils.isEmptyParagraph(this.presentationItems[0])) {
        let paragraph = PresentationUtils.defaultParagraph();
        this.presentationItems.splice(0, 0, paragraph);
        this.$nextTick(() => {
          this.getPresentationItemViewById(paragraph.guid).requireFocus();
        })
        this.doTriggerInputEvent();
      }
    },
    handleEditPanelDblClick: function () {
      if (!PresentationUtils.isEmptyParagraph(this.presentationItems[this.presentationItems.length - 1])) {
        let paragraph = PresentationUtils.defaultParagraph();
        this.presentationItems.push(paragraph);
        this.$nextTick(() => {
          this.getPresentationItemViewById(paragraph.guid).requireFocus();
        });
        this.doTriggerInputEvent();
      }
    },
    handleInput: function (e, index) {
      this.presentationItems.splice(index, e);
      this.doTriggerInputEvent();
    },
    getPresentationItemViewById(guid) {
      for (let presentationItemView of this.$refs["presentation-items"]) {
        if (presentationItemView.presentation.guid === guid) {
          return presentationItemView;
        }
      }
      return null;
    },
    doTriggerInputEvent: function () {
      let that = this;
      this.startUpload(this.presentationItems).then(res => {
        if (res.success) {
          that.$emit('input', this.presentationItems);
        }
      })
    }
  }
}
</script>

<style scoped>

.presentation-edit {
  border: 1px solid #dadada;
  border-radius: 16px;
  overflow: hidden;
  height: 50vh;
  max-width: 512px;
}

.operation-bar {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  border-bottom: 1px solid #dadada;
}

.operation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  cursor: pointer;
  user-select: none;
}

.operation:hover {
  background-color: #f1f1f1;
}

.operation .operation__icon {
  font-size: 16px;
}

.operation .operation__title {
  font-size: 10px;
}

.page-header {
  height: 20px;
  cursor: text;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-insert-paragraph {
  font-size: 10px;
  color: #bec0c0;
  visibility: hidden;
  text-align: center;
}

.page-header:hover .btn-insert-paragraph {
  visibility: visible;
}


</style>