<template>
  <div ref="input"
       class="input-presentation"
       contenteditable="true"
       :placeholder="placeholder"
       @input="handleInput"
       @keydown.enter="handleKeyEnter"
       @keydown.backspace="handleBackSpace"
       @blur="handleBlur"
       @focus="handleFocus"></div>
</template>

<script>
import ContentEditableHelper from "@/components/pretansation/basic/content-editable-helper";

export default {
  name: "InputPresentation",
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: String,
  },
  data() {
    return {
      valueInternal: null,
    }
  },
  watch: {
    value: {
      handler: function (value) {
        this.valueInternal = value;
      },
      immediate: true,
    },
    valueInternal: {
      handler: function (valueInternal) {
        if (this.$refs.input) {
          this.$refs.input.textContent = valueInternal;
        } else {
          this.$nextTick(() => {
            this.$refs.input.textContent = valueInternal;
          })
        }
      },
      immediate: true,
    }
  },
  methods: {
    focus: function () {
      ContentEditableHelper.init(this.$refs.input).requireFocus();
    },
    handleInput: function (e) {
      let content = e.currentTarget.textContent;
      this.valueInternal = content;
      this.$emit('input', this.valueInternal);
    },
    handleKeyEnter: function (e) {
      let insertBefore = ContentEditableHelper.init(this.$refs.input).getSelectionStart() === 0;
      this.$emit("keyenter", {insertBefore: insertBefore});
      e.returnValue = false;
    },
    handleBackSpace: function (e) {
      let selection = ContentEditableHelper.init(this.$refs.input).getSelection();
      if (selection.start === selection.end) {
        if (selection.start === 0) {
          this.$emit('keybackspace');
          e.returnValue = false;
        }
      } else {
        this.valueInternal = this.valueInternal.substring(0, selection.start) + this.valueInternal.substring(selection.end);
        this.$refs.input.textContent = this.valueInternal;
        ContentEditableHelper.init(this.$refs.input).requireFocus(selection.start);
        e.returnValue = false;
        this.$emit('input', this.valueInternal);
        this.$emit('keybackspace');
      }
    },
    handleBlur: function () {
      this.$emit('blur');
    },
    handleFocus: function () {
      this.$emit('focus');
    }
  }
}
</script>

<style scoped>

.input-presentation {
  outline: none;
  padding: 0px;
}

.input-presentation:empty:before {
  content: attr(placeholder);
  color: #bec0c0;
}
</style>