<template>
  <div :class="[
      'paragraph',
      {'is-edit': isEdit}
  ]" @click.stop="handleClickStop" @dblclick.stop="handleClickStop">
    <input-presentation
        ref="input"
        class="input-paragraph-content"
        v-model="paragraphInputted"
        :placeholder="isEdit ? '段落' : ''"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
        @keyenter="handleKeyEnter"
        @keybackspace="handleKeyBackspace">

    </input-presentation>
  </div>
</template>

<script>
import YsTextarea from "@/components/wedigets/YsTextarea";
import InputPresentation from "@/components/pretansation/basic/InputPresentation";

export default {
  name: "PresentationParagraph",
  components: {InputPresentation, YsTextarea},
  props: {
    presentation: Object,
  },
  data() {
    return {
      paragraphInputted: null,
      isEdit: false,
    }
  },
  watch: {
    presentation: {
      handler: function (presentation) {
        this.paragraphInputted = presentation.content;
      },
      immediate: true,
    },
  },
  methods: {
    requireFocus: function () {
      this.isEdit = true;
      this.$refs.input.focus();
    },
    handleClickStop: function () {

    },
    handleKeyEnter: function (e) {
      this.$emit('keyenter', {insertBefore: e.insertBefore && this.paragraphInputted});
    },
    handleFocus: function () {
      this.isEdit = true;
      this.$emit('focus');
    },
    handleBlur: function () {
      this.isEdit = false;
    },
    handleInput: function () {
      this.presentation.content = this.paragraphInputted;
      this.$emit('input', {value: this.presentation});
    },
    handleKeyBackspace: function () {
      this.$emit('keybackspace', {removed: !this.paragraphInputted});
    }
  }
}
</script>

<style scoped>

.paragraph-content {
  font-size: 16px;
  line-height: 32px;
  padding: 0px;
  padding-left: 16px;
  padding-right: 16px;
  text-indent: 16px;
}

.input-paragraph-content {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  line-height: 32px;
  text-indent: 16px;
}

.input-paragraph-content:after {
  content: "↵";
  color: #dadada;
}

</style>