<template>
  <div class="presentation-image layout-vertical layout-center layout-self-center font-align-center"
       @click.stop="handleClickStop" @dblclick.stop="handleClickStop">
    <input-presentation
        ref="inputHidden"
        class="input-hidden"
        @keydown.backspace.native="handleKeyBackspace"
        @keydown.enter.native="handleKeyEnter"
        @blur="handleImageBlur"
        @focus="handleImageFocus"
    ></input-presentation>
    <div class="image-mask__container font-align-left layout-self-stretch layout-vertical layout-right margin-right">
      <ys-button class="btn-delete margin-top-large margin-right-large" type="secondary" round @click="handleKeyBackspace">
        <i class="far fa-trash-alt font-color-danger"></i>
      </ys-button>
    </div>
    <el-image
        :class="[
            'image',
            {
              'is-active': isEdit,
            }
        ]"
        fit="contain"
        :src="$prepareImageUrl(presentation.image)"
        @click="handleClick"
    ></el-image>
    <div class="margin-top-small">
      <input-presentation
          class="input-image-title"
          placeholder="无标题"
          v-model="titleInputted"
          @input="handleInput"
          @blur="handleBlur"
          @focus="handleFocus"
          @keyenter="handleKeyEnter"
      ></input-presentation>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import InputPresentation from "@/components/pretansation/basic/InputPresentation";
import YsButton from "@/components/wedigets/YsButton";

export default {
  name: "PresentationImage",
  components: {YsButton, InputPresentation},
  mixins: [httpapi],
  props: {
    presentation: Object,
  },
  data() {
    return {
      isEdit: false,

      isImageFocus: false,
      isTitleFocus: false,

      titleInputted: null,
    }
  },
  watch: {
    presentation: {
      handler: function (presentation) {
        this.titleInputted = presentation.title;
      },
      immediate: true,
    }
  },
  methods: {
    requireFocus: function () {
      this.isEdit = true;
      this.handleClick();
      this.$emit('focus');
    },
    handleClickStop: function () {

    },
    handleClick: function () {
      this.isEdit = true;
      this.$emit('focus');
      this.$refs.inputHidden.focus();
    },
    handleBlur: function () {
      this.isTitleFocus = false;
      this.doRefreshFocusState();
    },
    handleFocus: function () {
      this.isTitleFocus = true;
      this.doRefreshFocusState();
    },
    handleImageBlur: function () {
      this.isImageFocus = false;
      this.doRefreshFocusState();
    },
    handleImageFocus: function () {
      this.isImageFocus = true;
      this.doRefreshFocusState();
    },
    handleInput: function () {
      this.presentation.title = this.titleInputted;
      this.$emit('input', this.presentation);
    },
    handleKeyEnter: function () {
      this.$emit('keyenter', {insertBefore: false});
    },
    handleKeyBackspace: function (e) {
      this.$emit('keybackspace', {removed: true});
      e.returnValue = false;
    },
    doRefreshFocusState: function () {
      this.isEdit = this.isImageFocus || this.isTitleFocus;
      if(this.isEdit) {
        this.$emit('focus');
      } else {
        this.$emit('blur');
      }
    }
  }
}
</script>

<style scoped>


.presentation-image .image {
  display: block;
  overflow: hidden;
  border-radius: 8px;
  user-select: none;
  margin-left: 16px;
  margin-right: 16px;
  border: 4px solid transparent;
  box-sizing: border-box;
}

.presentation-image .image.is-active {
  border: 4px solid #8cc5ff;
}

.input-image-title {
  padding: 0px !important;
  text-align: center;
  margin-left: 16px;
  margin-right: 16px;
  color: #bec0c0;
}

.input-image-title:before {
  content: "▲";
  color: #bec0c0;
}

.input-hidden {
  width: 0;
  height: 0;
}

.image-mask__container {
  height: 0px;
  z-index: 2;
}

.btn-delete {
  visibility: hidden;
}

.presentation-image:hover .btn-delete {
  visibility: visible;
}

</style>